//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      aaa: JSON.parse(localStorage.getItem('registername')),
      types: JSON.parse(localStorage.getItem('roleType')),
      starlevel: Number(JSON.parse(localStorage.getItem('starlevel'))),
      dname: JSON.parse(localStorage.getItem('dname')),
    }
  },

  methods: {
    handleCommand(command) {
      this.$router.push(command)
    },
    signout() {
      this.$confirm('是否确认退出', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        //删除token
        this.$cookies.remove('token')
        //跳回首页
        this.$router.push('/homes/login')
        localStorage.clear()
        sessionStorage.clear()
      })
    },
  },
}
