//
//
//
//
//
//
//
//
//
//
//

//tab对应的组件
import Headers from "@/components/Headers.vue"
export default {
  data(){
    return {
      taglist : [
        {
          path : '/main/service/services',
          name : '服务商',
          style : 'transform: translateX(0);;z-index : 4;'
        }
      ]
    }
  },
  components : {
    Headers
  }
}
