//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//引入右侧退出组件
import UserMenu from "@/components/UserMenu.vue";
export default {
  props: ["data"],
  components: {
    UserMenu,
  },
  data(){
    return {
    }
  },
  mounted() {
  },
};
